window.switch_checkbox = function(checkbox_id) {
    var e = document.getElementById(checkbox_id);
    var checked = e.checked;
    var classes = e.getAttribute('class').split(' ');
    $('#' + checkbox_id).prop('checked', checked == false );
    checkboxChanged(classes, checked == false);
}

window.checkboxChanged = function(p_classes, p_checked) {
    if ( Array.isArray((p_classes)) ) {
        // called by script
        var checked = p_checked;
        var cl = p_classes;
        var classes = sort_classes_cb(cl);
    } else {
        // triggered by checkbox
        var checked = this.checked;
        var cl = this.getAttribute('class').split(' ');
        var classes = sort_classes_cb(cl);
    }


    var my_class = classes.my.split('_')[0];


    $('#dev-view-2').html(":my_class => " + my_class + ' :all_classes => ' + cl + ' :parent_classes => ' + classes.parents + ' :type => ' + classes.type);


    // set children
    if (classes.type == 'issue' || classes.type == 'parent' || classes.type == 'project') {
        $('.' + my_class).prop('checked', checked);
    }

    // set parents
    var cla = classes.parents.split(' ')
    cla.map(function (par) {
      //  $('.' + par).prop('checked', checked);
    });


}

window.sort_classes_cb = function(classes) {
    var my = '';
    var par = '';
    var type = '';
    classes.map(function (c) {
        if (c.split('_')[1] == 'ME') {
            my = c;
        } else if (c.split('-').length == 2) {
            par = par + c + ' ';
        } else {
            type = c;
        }
    });
    return {'my': my, 'parents': par, 'type': type};
}

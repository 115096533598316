import {Controller} from "@hotwired/stimulus"


export default class extends Controller {

    connect() {
        this.element.addEventListener('click', (e) => {
            this.toggle()
        })
        this.element.addEventListener('expand', (e) => {
            this.expand_list()
        })
        this.element.addEventListener('collapse', (e) => {
            this.collapse_list()
        })
    }

    toggle() {
        const li = this.element.closest('li')
        if (li.classList.contains('children-expanded')) {
            this.collapse_list()
        } else {
            this.expand_list()
        }
    }

    collapse_list() {
        const li = this.element.closest('li')
        li.classList.remove('children-expanded')
        li.classList.add('children-collapsed')
    }

    expand_list() {
        const li = this.element.closest('li')
        li.classList.add('children-expanded')
        li.classList.remove('children-collapsed')
    }


}
import {Controller} from "@hotwired/stimulus"

export default class extends Controller {

    connect() {
        $(this.element).on('click', (e) => this.confirm_action(e))
    }

    confirm_action(e) {
        e.preventDefault()
        if(confirm('Sind Sie sicher?')) {
            this.element.parentElement.querySelector('a.remove_fields').click()
        }
    }


}
// import "foundation-sites"
//
// $(document).on('turbolinks:load', function () {
//     $(document).foundation()
// });
// import 'foundation-sites/dist/css/foundation.min.css';


window.posting_change_year = function () {
    // var inp = $('#accounting_position_accounting_year');
    // $.get('/accounting_year/set_current_year?year=' + inp.val(), function (data) {
    //     var y = JSON.parse(data);
    //     $('#accounting_position_document_no').val(y.next_document_number);
    // });
};

window.postings_select_invoice = function (no) {
    $('#accounting_position_document_no').val(no);
    postings_close_invoice_select();
};

window.postings_close_invoice_select = function () {
    $('#select-invoices-dropdown').addClass('hidden');
};

window.bank_position_change_account = function (transaction_id) {
    var e = event.target;
    $.get('/bank_transactions/update_account_id?transaction_id=' + transaction_id + '&account_key=' + e.value, function (data) {
        var js = JSON.parse(data);
        if (js.private == 'true' || e.value == '') {
            $('#post-btn-' + transaction_id).addClass('hidden');
        } else {
            $('#post-btn-' + transaction_id).removeClass('hidden');
        }
        ;
        if (js.success) {
            const sel = document.getElementById('accounts-select-' + transaction_id)
            if (e.value === '') {
                sel.classList.add('selected-auto');
            } else {
                sel.classList.remove('selected-auto');
            }
        }
    });
};

window.posting_form_calculate_vat = function () {

    console.log('START posting_vat_form')

    const vat_id = document.getElementById('accounting_position_vat_id').value;
    // const doc_date_input = document.getElementById('accounting_position_vat_date');
    // var show_vat_date = (doc_date_input.value !== '')
    //  var vat_date_error = false;

    var request = new XMLHttpRequest();
    request.open('GET', '/accounting_vat_rates/get_rate?id=' + vat_id, false);
    request.send(null);

    if (request.status === 200) {
        const res = document.getElementById('vat')
        if (!JSON.parse(request.responseText)) {
            res.innerHTML = '-';
        } else {
            const rec = parseFloat(document.getElementById('accounting_position_receipts').value);
            const exp = parseFloat(document.getElementById('accounting_position_expenses').value);
            console.log('->' + request.responseText)
            const js = JSON.parse(request.responseText)
            const vat_rate = parseFloat(js.rate)
            console.log('vat-rate => ' + vat_rate)
            let val = null;
            if (!isNaN(rec) && isNaN(exp)) {
                val = rec;
            } else if (isNaN(rec) && !isNaN(exp)) {
                val = exp;
            } else if (isNaN(rec) && isNaN(exp)) {
                res.innerHTML = '0';
            } else {
                res.innerHTML = '?';
            }
            if (val) {
                console.log('val => ' + val)
                const vat = val / (100 + vat_rate) * vat_rate;
                res.innerHTML = (isNaN(vat) ? '-' : vat.toFixed(2));
            } else {
                console.log('val empty')
            }
        }


        // // VAT DATE
        // const vat_date = js.vat_date;
        // if (vat_date) {show_vat_date = true};
        // if (doc_date_input.value !== '' && !js.vat_date) {
        //     vat_date_error = true;
        // }
    }
    // const inp = document.getElementsByClassName('accounting_position_vat_date')[0];
    // const wraps = document.querySelector('div.accounting_position_date');
    // const label = wraps.querySelectorAll('label')[0];
    // if (show_vat_date) {
    //     inp.classList.remove('hidden');
    //     label.innerHTML = 'Zahlung Datum';
    // } else {
    //     label.innerHTML = 'Datum';
    //     inp.classList.add('hidden')
    // }
    // if (vat_date_error) {
    //     inp.classList.add('error')
    // } else {
    //     inp.classList.remove('error')
    // }

}

window.change_account = function () {
    var acc_id = document.getElementById('accounting_position_account_id').value;
    var request = new XMLHttpRequest();
    request.open('GET', '/accounting_vat_rates/get_account?id=' + acc_id, false);
    request.send(null);
    if (request.status === 200) {
        console.log('change-account-status 200')
        const r = JSON.parse(request.responseText);
        console.log('change-account-response ' + r)
        const inp = document.getElementById('accounting_position_vat_id');
        inp.value = r.vat_id;
        posting_form_calculate_vat();
    }
}

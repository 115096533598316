import {Controller} from "@hotwired/stimulus"
import axios from "axios";

export default class extends Controller {

    connect() {
        const td = this.element.closest('td')
        const dropdown = td.querySelector('.dropdown-pane')
        try {
            $(dropdown).foundation('close')
        } catch {
        }

    }

}
window.edit_timelogger = function() {
    $('.timelogger-showing').hide();
    var ed = $('.time-logger-editing');
    ed.show();
    ed.siblings('.up').hide();
}

window.edit_timelogger_cancel = function() {
    $('.timelogger-showing').show();
    $('.time-logger-editing').hide();

    var e = document.getElementById('timelogger-issue-id-input-wrapper-1');
    $(e).children('.up').hide();
    $(e).children('.down').show();

    var p = $('#check-issues-popup-' + te_id);
    p.html("");
    p.removeClass('show');
}

window.edit_timelogger_save = function() {
    var issue_id = $('#timelogger-input-issue_id').val();
    var date = $('#timelogger-input-date').val();
    var time = $('#timelogger-input-time').val();
    var url = 'times/edit_timelogger?issue_id=' + issue_id + '&date=' + date + '&time=' + time;
    $.post(url);
}

window.timelogger_edited = function(error_message) {
    if (error_message) {
        alert(error_message);
    } else {
        location.reload();
    }
}

window.check_issue_id = function(original_issue_id, timeentry_id) {
    var e = event.target;
    var issue_id = $(e).val();
    var d = new Date();
    var ts = d.getTime();
    var url = '/times/check_issue_id?issue_id=' + issue_id + '&original_issue_id=' + original_issue_id;
    var popup = document.getElementById('check-issues-popup-' + timeentry_id);
    popup.setAttribute('process', ts);
    $.get(url, function (data) {
        if (data) {
            $(popup).addClass('show');
            $(popup).html(data);
            setTimeout(function () {
                var process = popup.getAttribute('process');
                if (ts == process) {
                    $(popup).html('');
                    $(popup).removeClass('show');
                }
            }, 3000);
        }
    });
    var e = document.getElementById('timelogger-issue-id-input-wrapper');
    $(e).children('.up').hide();
    $(e).children('.down').show();
}

window.close_issues_select = function(timeentry_id) {
    //var p = $('#check-issues-popup-' + timeentry_id);
    const p = document.getElementById('check-issues-popup-' + timeentry_id)
    p.innerHTML = '';
    p.classList.remove('show');
    if (timeentry_id) {
        var el = document.getElementById('timelogger-issue-id-input-wrapper-' + timeentry_id);
        $(el).children('.up').hide();
        $(el).children('.down').show();
        $(el).children('.down').children().show();
    } else {
        $(e).children('.down').show();
    }
}

window.time_tracker_select_issue = function(issue_id, timeentry_id) {
    var e = document.getElementById('timelogger-issue-id-input-wrapper-' + timeentry_id);
    $(e).children('input').val(issue_id);
    close_issues_select(timeentry_id);
}
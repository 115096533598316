export function clear_selected_user() {

    const elements = document.querySelectorAll('.does-not-contain-user')
    for (let i = 0; i < elements.length; i++) {
        elements[i].classList.remove('does-not-contain-user')
    }

    // const btns = document.querySelectorAll('span.selected-user')
    // for (let i = 0; i < btns.length; i++) {
    //     btns[i].classList.remove('selected-user')
    // }
    const user_buttons = document.querySelectorAll('span.user-times-button')
    for (let i = 0; i < user_buttons.length; i++) {
        user_buttons[i].classList.remove('does-not-contain-user')
    }

    const par = document.getElementById('users-costs')
    par.removeAttribute('data-selected-user')
}


window.select_accounts_open = function (location) {
    fetch('/accounting_position/select_account_js?location=' + location)
        .then(response => response.text())
        .then(res => (select_accounts_fill(res)))
}

function select_accounts_fill(response) {
    const e = document.getElementById('accounts-dropdown-content');
    e.innerHTML = response
}

window.toggle_doc_no_buttons = function (action) {
    console.log(action)
    if (action === 'enable') {
        console.log('=> enable')
        $('.generate-doc-no-placeholder').addClass('hidden');
        $('.generate-doc-no-btn').removeClass('hidden');
        $('#gen-doc-enable-button').addClass('hidden');
        $('#gen-doc-disable-button').removeClass('hidden');
    } else {
        console.log('=> disable')

        $('.generate-doc-no-placeholder').removeClass('hidden');
        $('.generate-doc-no-btn').addClass('hidden');
        $('#gen-doc-enable-button').removeClass('hidden');
        $('#gen-doc-disable-button').addClass('hidden');
    }

}

window.open_popover_match_history = function (transaction_id, action_name, posting_id) {
    const id = (transaction_id === '' ? posting_id : transaction_id)
    fetch('/bank_transactions/match_history_popover_content?transaction_id=' + transaction_id + '&action_name=' + action_name + '&posting_id=' + posting_id)
        .then(response => response.text())
        .then(res => (_match_history_popover_content(res, id)))

}

function _match_history_popover_content(response, id) {
    const e = document.getElementById('match-history-popover-' + id);
    e.innerHTML = response
}

function open_popover_match_history_keyword(id, action_name) {
    fetch('/keywords/match_history_popover_content?id=' + id + '&action_name=' + action_name)
        .then(response => response.text())
        .then(res => (_match_history_keyword_popover_content(res, id)))

}

function _match_history_keyword_popover_content(response, id) {
    const e = document.getElementById('match-history-popover-' + id);
    e.innerHTML = response
}

window.check_posting = function (id) {
    const e = document.getElementById('posting-check-' + id)
    var request = new XMLHttpRequest();
    request.open('GET', '/accounting_position/check_posting?id=' + id + '&checked=' + e.checked, false);  // `false` makes the request synchronous
    request.send(null);
    if (request.status !== 200 || request.responseText !== 'SUCCESS') {
        alert('FEHLER: ' + request.responseText + request.status);
    }
}

function add_accounting_position() {
    const inp_wrap = document.getElementById('vat-inputs');

    var request = new XMLHttpRequest();
    request.open('GET', '/accounting_position/vat_input_position', false);  // `false` makes the request synchronous
    request.send(null);

    if (request.status === 200) {
        alert(request.responseText);
    }
}

window.check_keyword_from_string = function () {
    const e = document.getElementById('accounting_position_subject');
    const backup = document.getElementById('posting-subject-backup');
    if (e.value !== backup.innerHTML) {
        backup.innerHTML = e.value;


        let xhr = new XMLHttpRequest();
        xhr.open('GET', '/accounting_position/check_keyword?string=' + encodeURI(e.value), false);

        try {
            xhr.send();
            if (xhr.status != 200) {
                alert(`Error ${xhr.status}: ${xhr.statusText}`);
            } else {
                const js = JSON.parse(xhr.response);
                if (js.found) {

                    // SUCCESS
                    const acc_in = document.getElementById('accounting_position_account_key');
                    const ust_input = document.getElementById('accounting_position_vat_id');
                    acc_in.value = js.account_id_res;
                    ust_input.value = js.vat_id;


                }
            }
        } catch (err) { // instead of onerror
            alert(`Request failed: ${err}`);
        }
    }
}

window.exit_accounting_date = function () {
    console.log('start script exit accounting date')
    const e = document.getElementById('accounting_position_date');
    const period_input = document.getElementById('accounting_position_accounting_period_id');
    const backup = document.getElementById('posting-date-backup');
    if (e.value !== backup.innerHTML) {
        backup.innerHTML = e.value;


        let xhr = new XMLHttpRequest();
        xhr.open('GET', '/accounting_periods/find_period?date=' + encodeURI(e.value), false);

        try {
            xhr.send();
            if (xhr.status != 200) {
                alert(`Error ${xhr.status}: ${xhr.statusText}`);
            } else {
                const js = JSON.parse(xhr.response);
                if (!js.is_closed) {

                    // SUCCESS
                    // if (!period_input.value) {
                    period_input.value = js.id;
                    change_accounting_period();
                    //}


                } else {
                   alert(`The Period ${js.title} is closed`)
                }
            }
        } catch (err) { // instead of onerror
            alert("Request failed");
        }
    }
}

function change_accounting_period() {
    const backup_year = document.getElementById('posting-year-backup');
    const period_input = document.getElementById('accounting_position_accounting_period_id');
    let xhr = new XMLHttpRequest();
    xhr.open('GET', '/accounting_periods/get_period?id=' + period_input.value, false);
    try {
        xhr.send();
        if (xhr.status != 200) {
            alert(`Error ${xhr.status}: ${xhr.statusText}`);
        } else {
            const js = JSON.parse(xhr.response);
            if (backup_year.innerHTML !== js.year) {
                backup_year.innerHTML = js.year;
                const year_in = document.getElementById('accounting_position_document_number');
                year_in.value = js.next_document_number;
            }
        }
    } catch (err) { // instead of onerror
        alert("Request failed");
    }
}
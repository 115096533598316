import * as echarts from "echarts";

window.init_accounting_position_charts = function () {
    init_year_pie_chart_balance()
    init_year_pie_chart_revenues()
    init_year_pie_chart_expenses()
    init_year_pie_chart_vat()
    init_year_pie_chart_balance_month()
    init_year_pie_chart_balance_period()
}

window.init_year_pie_chart_expenses = function () {
    // https://echarts.apache.org/examples/en/editor.html?c=pie-simple
    const wrapper = document.getElementById('pie-chart-year-expenses')
    wrapper.innerHtml = 'starting'
    var chart = echarts.init(wrapper)
    const option = {
        title: {
            text: 'Ausgaben Netto',
            subtext: '',
            left: 'center'
        },
        tooltip: {
            trigger: 'item'
        },
        legend: {
            orient: 'vertical',
            left: 'left',
            show: false
        },
        series: [
            {
                name: 'Ausgaben',
                type: 'pie',
                radius: '50%',
                data: gon.expenses,
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                }
            }
        ]
    };
    chart.setOption(option);
}


window.init_year_pie_chart_revenues = function () {
    // https://echarts.apache.org/examples/en/editor.html?c=pie-simple
    const wrapper = document.getElementById('pie-chart-year-revenues')
    wrapper.innerHtml = 'starting'
    var chart = echarts.init(wrapper)
    const option = {
        title: {
            text: 'Einnahmen Netto',
            subtext: '',
            left: 'center'
        },
        tooltip: {
            trigger: 'item'
        },
        legend: {
            orient: 'vertical',
            left: 'left',
            show: false
        },
        series: [
            {
                name: 'Einnahmen',
                type: 'pie',
                radius: '50%',
                data: gon.revenues,
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                }
            }
        ]
    };
    chart.setOption(option);
}

window.init_year_pie_chart_balance = function () {
    // https://echarts.apache.org/examples/en/editor.html?c=pie-simple
    const wrapper = document.getElementById('pie-chart-year-balance')
    wrapper.innerHtml = 'starting'
    var chart = echarts.init(wrapper)
    const option = {
        title: {
            text: 'Bilanz Netto',
            subtext: '',
            left: 'center'
        },
        tooltip: {
            trigger: 'item'
        },
        legend: {
            orient: 'vertical',
            left: 'left',
            show: false
        },
        series: [
            {
                name: 'Bilanz',
                type: 'pie',
                radius: '50%',
                data: gon.balance,
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                }
            }
        ]
    };
    chart.setOption(option);
}

window.init_year_pie_chart_vat = function () {
    // https://echarts.apache.org/examples/en/editor.html?c=pie-simple
    const wrapper = document.getElementById('pie-chart-year-tax')
    wrapper.innerHtml = 'starting'
    var chart = echarts.init(wrapper)
    const option = {
        title: {
            text: 'Bezahlte Steuern',
            subtext: '',
            left: 'center'
        },
        tooltip: {
            trigger: 'item'
        },
        legend: {
            orient: 'vertical',
            left: 'left',
            show: false
        },
        series: [
            {
                name: 'Bezahlte Steuer',
                type: 'pie',
                radius: '50%',
                data: gon.vat,
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                }
            }
        ]
    };
    chart.setOption(option);
}

window.init_year_pie_chart_balance_month = function () {
    // https://echarts.apache.org/examples/en/editor.html?c=pie-simple
    const wrapper = document.getElementById('init_year_pie_chart_balance_month')
    wrapper.innerHtml = 'starting'
    var chart = echarts.init(wrapper)
    const option = {
        title: {
            text: ''
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross',
                label: {
                    backgroundColor: '#6a7985'
                }
            }
        },
        legend: {
        },
        toolbox: {
            feature: {
                saveAsImage: {}
            }
        },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                boundaryGap: false,
                data: gon.monthly_balance_months,
            }
        ],
        yAxis: [
            {
                type: 'value'
            }
        ],
        series: gon.monthly_balance
    };
    chart.setOption(option);
}

window.init_year_pie_chart_balance_period = function () {
    // https://echarts.apache.org/examples/en/editor.html?c=pie-simple
    const wrapper = document.getElementById('init_year_pie_chart_balance_period')
    wrapper.innerHtml = 'starting'
    var chart = echarts.init(wrapper)
    const option = {
        title: {
            text: ''
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross',
                label: {
                    backgroundColor: '#6a7985'
                }
            }
        },
        legend: {
        },
        toolbox: {
            feature: {
                saveAsImage: {}
            }
        },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                boundaryGap: false,
                data: gon.period_balance_periods,
            }
        ],
        yAxis: [
            {
                type: 'value'
            }
        ],
        series: gon.period_balance
    };
    chart.setOption(option);
}
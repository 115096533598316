window.remove_invoice_position = function (id) {
    console.log(' ==>> ID: ' + id)
    var wrapper = document.querySelector('#invoice-position-wrapper-' + id);
    const select = wrapper.querySelector('#accounting_invoice_position_unit')
    select.value = '[delete-me]';
    const price = wrapper.querySelector('#accounting_invoice_position_price_unit')
    price.value = 0

    var count = wrapper.querySelector('#accounting_invoice_position_quantity');
    count.value = 0;
    wrapper.style.display = 'none';

    invoice_summarize();
};


window.invoice_calc = function (id) {
    var q = document.getElementsByClassName(id + '-quantity')[0].value;
    var u = document.getElementsByClassName(id + '-unit-net')[0].value;
    var r = Number(q) * Number(u);
    var re = document.getElementsByClassName(id + '-net')[0];
    re.innerHTML = r.toFixed(1);

    invoice_summarize();
};

window.invoice_summarize = function () {
    var pos = document.getElementsByClassName('position-net');
    if (pos.length >= 1) {
        var total = 0;
        for (var i = 0; i < pos.length; i++) {
            total = total + Number(pos[i].innerHTML);
        }
        ;
        document.getElementById('total-net').innerHTML = Math.round(total).toFixed(2);
        const vat_rate = Number.parseFloat(document.getElementById('accounting_invoice_vat_rate').value);
        if (isNaN(vat_rate)) {
            document.getElementById('total-gross').innerHTML = Math.round(total).toFixed(2);
        } else {
            console.log('=>' + vat_rate);
            const vat = total / 100 * vat_rate;
            document.getElementById('vat').innerHTML = Math.round(vat).toFixed(2);
            document.getElementById('total-gross').innerHTML = Math.round(vat + total).toFixed(2);
        }
    }
};

window.add_invoice_position = function (customer_id) {

    let xhr = new XMLHttpRequest();
    xhr.open('GET', `/invoices/form_add_position?customer_id=${customer_id}`);
    xhr.send();
    xhr.onload = function () {
        if (xhr.status != 200) {
            alert(`Error ${xhr.status}: ${xhr.statusText}`);
        } else {
            let w = document.getElementById('invoice-positions-wrapper');

            let html = $.parseHTML(xhr.responseText);

            console.log(html)

            w.append(html[0])
        }
    };
}


// XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX


window.bind_listeners = function () {
    var search_input = document.getElementById("search-input");
    search_input.addEventListener("keyup", function (event) {
        k = event.keyCode;
        if (k === 13) {
            search_times();
        }
    });
    search_input.addEventListener("keydown", function () {
        $('#sii-dropdown-button').hide();
        $('#sii-close-menu-button').hide();
    });
    search_input.addEventListener("click", function () {
        $('#sii-dropdown-button').show();
    });
    // $(window).on('resize',alert('resize'));
}

function close_customers_list() {
    document.getElementById('customers-list').style.display = 'none'
}

window.align_links_in_table = function () {
    // var e = $('a.single-link');
    // var wp = e.parent().width();
    // var w = e.width();
    // //e.parent().html(wp);
    // $("#status").html('wp');
    //
    // e.css({left: (wp/2) - (w/2)});


}

window.toggle_times = function () {
    var l = $('#hide-times-button').html();
    if (l == 'Hide Times') {
        $('#hide-times-button').html('Show Times');
        $('.timeentry-li').hide();
    } else {
        $('#hide-times-button').html('Hide Times');
        $('.timeentry-li').show();
    }

}

window.select_times = function (customer_id, hours_total) {
    const select_url = document.getElementById('select_times_url').innerHTML
    // var select_url = $('#select_times_url').html();
    //var date_from = $('#customers-list-date-from-' + customer_id).val();
    const df = document.getElementById('customers-list-date-from-' + customer_id)
    if (df) {
        const date_from = df.value
        const date_until = document.getElementById('customers-list-date-until-' + customer_id).value
        var sel_inv = select_url + '?hours=' + hours_total + '&customer_id=' + customer_id + '&date_from=' + date_from + '&date_until=' + date_until;
    } else {
        var sel_inv = select_url + '?hours=' + hours_total + '&customer_id=' + customer_id;
    }
    //var date_until = $('#customers-list-date-until-' + customer_id).val();
    window.location = sel_inv
}

window.search_times = function () {
    var select_url = $('#find_times_url').html();
    var sel_inv = select_url + '?search_string=' + encodeURIComponent($('#search-input').val());
    window.location = sel_inv
}

window.customer_switch_month = function (mode, id) {
    console.log(mode + '=>' + id)
    const e_from = document.getElementById('customers-list-date-from-' + id)
    const e_until = document.getElementById('customers-list-date-until-' + id)
    var d_u = e_until.value.split('.');
    var d_until = new Date(d_u[2], d_u[1] - 1, 28);
    console.log('vars')
    if (mode == 'up') {
        d_until.setDate(d_until.getDate() + 35);
    }
    var d_until_2 = new Date(d_until.getFullYear(), d_until.getMonth(), 1);
    d_until_2.setDate(d_until_2.getDate() - 1);
    var until_str = day(d_until_2) + '.' + month(d_until_2) + '.' + d_until_2.getFullYear();
    var from_str = '01.' + month(d_until_2) + '.' + d_until_2.getFullYear();
    e_from.value = from_str;
    e_until.value = until_str;

    let xhr = new XMLHttpRequest();
    xhr.open('GET', '/times/times_time_range?customer_id=' + id + '&from=' + encodeURI(from_str) + '&until=' + encodeURI(until_str));
    xhr.send();
    xhr.onload = function () {
        if (xhr.status != 200) {
            alert(`Error ${xhr.status}: ${xhr.statusText}`);
        } else {
            const js = JSON.parse(xhr.response)
            e_hours = document.getElementById('customers-list-' + id + '-hours')
            e_hours.innerHTML = js.times_title
        }
    };


}

window.month = function (date) {
    var m = '0' + (date.getMonth() + 1);
    return m.slice(-2);
}

function day(date) {
    var m = '0' + date.getDate();
    return m.slice(-2);
}

window.sii_open_dropdown = function () {
    var dd = $('#search-times-select-wrapper');
    dd.show();
    $('#sii-close-menu-button').show();
    $('#sii-dropdown-button').hide();
}

window.sii_close_dropdown = function (options) {
    var dd = $('#search-times-select-wrapper');
    dd.hide();
    $('#sii-close-menu-button').hide();
    if (options != 'for-searching') {
        $('#sii-dropdown-button').show();
    }
}

window.sii_search = function (key) {
    sii_close_dropdown('for-searching');
    $('#search-input').val(key);
    search_times();
}

window.invoice_change_project = function () {
    const e = document.getElementById('accounting_invoice_project_id')
    const add_costs = document.querySelector('div.accounting_invoice_project_additional_costs_net')
    const hours = document.querySelector('div.accounting_invoice_hours')
    const note = document.querySelector('div.accounting_invoice_intern_note')
    const progress = document.querySelector('div.accounting_invoice_project_progress')
    if (e.value) {
        progress.classList.remove('hidden')
        add_costs.classList.remove('hidden')
        hours.classList.remove('hidden')
        note.classList.remove('hidden')
    } else {
        progress.classList.add('hidden')
        add_costs.classList.add('hidden')
        hours.classList.add('hidden')
        note.classList.add('hidden')
    }
}






window.check_select_times = function () {
    const e = event.target
    const checked = $(e).prop('checked');
    const par = $(e).closest('li');
    const inputs = $(par).find('input[type="checkbox"]')
    inputs.prop('checked', checked)
}



import {Controller} from "@hotwired/stimulus"
import {clear_selected_user} from "@/javascript/views/recent-times/clear_selected_user";


export default class extends Controller {

    connect() {
        const action = this.element.getAttribute('data-action')
        this.element.addEventListener('click', (e) => {
            clear_selected_user()
            const wrap = this.element.closest('#togglers')
            if (action === 'toggle-cost-centers' && wrap.classList.contains('cost-centers-collapsed')) {
                this.expand_cost_centers()
            } else if (action === 'toggle-cost-centers') {
                this.collapse_cost_centers()
            } else if (action === 'toggle-issues' && wrap.classList.contains('issues-collapsed')) {
                this.expand_issues()
            } else if (action === 'toggle-issues') {
                this.collapse_issues()
            }
        })
        if (action === 'toggle-issues') {
            this.element.addEventListener('close', (e) => {
                this.collapse_issues()
            })
        }
    }

    expand_issues() {
        const iss = document.querySelectorAll('li.issue')
        for (var i = 0; iss.length > i; i++) {
            iss[i].classList.add('children-expanded')
            iss[i].classList.remove('children-collapsed')
        }
        document.cookie = 'current_times_issues_collapsed ='

        const wrap = this.element.closest('#togglers')
        wrap.classList.remove('issues-collapsed')
    }

    collapse_issues() {
        const iss = document.querySelectorAll('li.issue')
        for (var i = 0; iss.length > i; i++) {
            iss[i].classList.remove('children-expanded')
            iss[i].classList.add('children-collapsed')
        }
        document.cookie = 'current_times_issues_collapsed = true'

        const wrap = this.element.closest('#togglers')
        wrap.classList.add('issues-collapsed')
    }

    expand_cost_centers() {
        const cc = document.querySelectorAll('li.cost-center')
        for (var i = 0; cc.length > i; i++) {
            cc[i].classList.add('children-expanded')
            cc[i].classList.remove('children-collapsed')
        }
        document.cookie = 'current_times_cost_centers_collapsed ='

        const wrap = this.element.closest('#togglers')
        wrap.classList.remove('cost-centers-collapsed')
    }

    collapse_cost_centers() {
        const cc = document.querySelectorAll('li.cost-center')
        for (var i = 0; cc.length > i; i++) {
            cc[i].classList.remove('children-expanded')
            cc[i].classList.add('children-collapsed')
        }
        document.cookie = 'current_times_cost_centers_collapsed = true'

        const wrap = this.element.closest('#togglers')
        wrap.classList.add('cost-centers-collapsed')

        const iss_wrapper = document.getElementById('toggle-issues-wrapper')
        const ev = new Event('close')
        iss_wrapper.dispatchEvent(ev)
    }


}
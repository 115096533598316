function hover_top_menu(mode) {

    // POSTINGS SELECT ACCOUNT

    const inp = document.getElementById('find-form');
    if (inp) {
        if (mode === 'enter') {
            inp.style.display = 'none';
        } else {
            inp.style.display = 'block';
        }
    }

    // FLASH

    const flash = document.getElementsByClassName('callout alert-callout-border');
    for (var i = 0; flash.length; i++) {
        var f = flash[i];
        f.style.display = 'none';
    }
}

window.init_session_layout = function () {
    document.addEventListener("turbo:render", function () {
        _after_page_load()
    })
    _after_page_load();
}

function _after_page_load() {

    // if posting-window active
    const posting_win = document.getElementById('posting-form');
    if (posting_win) {
        const date_input = document.getElementById('accounting_position_date');
        date_input.focus();
        posting_form_calculate_vat();
        check_keyword_from_string();
        //exit_accounting_date();

    }

    const edit_times = document.getElementsByClassName('times_edit_times')
    if ( edit_times.length >= 1 ) {
        edit_times_onload()
    }
}
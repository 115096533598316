import {Controller} from "@hotwired/stimulus"
import axios from "axios";

export default class extends Controller {

    connect() {
        const td = this.element.closest('td');
        const breaker = td.querySelector('br')
        const new_breaker = document.createElement('br')
        const have_content = this.element.getAttribute('data-content');
        if ( have_content === 'yes' ) {
            td.classList.add('replace-subject')
            if ( !breaker ) { td.insertBefore(new_breaker, this.element) }
        } else {
            td.classList.remove('replace-subject')
            if ( breaker ) { breaker.remove() }
        }
        const dropdown = td.querySelector('.dropdown-pane')
        try {
            $(dropdown).foundation('close')
        }
        catch(err){}

    }

}
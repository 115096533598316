import {Controller} from "@hotwired/stimulus"

export default class extends Controller {

    connect() {
        this.element.addEventListener('click', () => {
            this.toggle()
        })
    }

    toggle() {
        let panel = this.element.closest('.stimulus-dropdown-panel')
        let id = this.element.getAttribute('data-toggle')
        let element = panel.querySelector(`#${id}`)

        let chev_right = this.element.querySelector('.chevron-right')
        let chev_down = this.element.querySelector('.chevron-down')

        if (element.classList.contains('hide')) {
            element.classList.remove('hide')
            chev_right.classList.add('hide')
            chev_down.classList.remove('hide')
        } else {
            element.classList.add('hide')
            chev_right.classList.remove('hide')
            chev_down.classList.add('hide')
        }

        let dropdown_button = document.querySelector(`[data-toggle="${panel.id}"]`)
        let event = new Event("place-panel")
        dropdown_button.dispatchEvent(event)
    }


}
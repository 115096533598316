import {Controller} from "@hotwired/stimulus"
import axios from "axios";

export default class extends Controller {

    connect() {
        this.element.onchange = () => this.change_billing_type()
        this.change_billing_type()
    }

    change_billing_type() {
        const form = this.element.closest('form')
        form.setAttribute('billing-type', this.element.value)
    }


}
window.onload_init_overview = function() {
    // Opera 8.0+
    var isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;

// Firefox 1.0+
    var isFirefox = typeof InstallTrigger !== 'undefined';

// Safari 3.0+ "[object HTMLElementConstructor]"
    var isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification));

// Internet Explorer 6-11
    var isIE = /*@cc_on!@*/false || !!document.documentMode;

// Edge 20+
    var isEdge = !isIE && !!window.StyleMedia;

// Chrome 1 - 71
    var isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

// Blink engine detection
    var isBlink = (isChrome || isOpera) && !!window.CSS;


    var output = 'Detecting browsers by ducktyping:<hr>';
    output += 'isFirefox: ' + isFirefox + '<br>';
    output += 'isChrome: ' + isChrome + '<br>';
    output += 'isSafari: ' + isSafari + '<br>';
    output += 'isOpera: ' + isOpera + '<br>';
    output += 'isIE: ' + isIE + '<br>';
    output += 'isEdge: ' + isEdge + '<br>';
    output += 'isBlink: ' + isBlink + '<br>';
    var brow = '$.browser.chrome';
   // $('#browser-warning').html('browser_ ' + $.browser.name);
    //$('#browser-warning').html(output);
//    document.body.innerHTML = output;


    var e = $('#overview-title');
    var zInd = e.css('z-index');
    var zInd2 = $('#customers-list').css('z-index');
   // alert ( zInd );
   // alert ( zInd2 );


}

window.overview_show_subprojects = function(id) {
    $('.subproject-from-' + id ).show();
    $('#hide-subprojects-' + id).show();
    $('#show-subprojects-' + id).hide();
}

window.overview_hide_subprojects = function(id) {
    $('.subproject-from-' + id ).hide();
    $('#hide-subprojects-' + id).hide();
    $('#show-subprojects-' + id).show();
}

window.open_tooltip = function(tooltip_no, issue_ids, title, e, show_projects) {
    e.preventDefault();
    var tt = $('#tooltip-' + tooltip_no);
    var ttc = $('.tooltip-wrapper');
    ttc.removeClass('tooltiptext');
    ttc.html('');
    ttc.hide();
    tt.addClass('tooltiptext');
    tt.show();
    tt.css('left', (e.pageX - 370) + 'px');
    $.get('overview_tooltip?issue_ids=' + issue_ids + '&title=' + title + '&show_projects=' + show_projects, function(data, status){
        if (status == 'success') {
            tt.html(data);
        }
    });
    return false;
}

window.close_tooltip = function(tooltip_no) {
    var tt = $('#tooltip-' + tooltip_no);
    tt.removeClass('tooltiptext');
    tt.html('');
    tt.hide();
}
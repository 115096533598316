import {Controller} from "@hotwired/stimulus"

export default class extends Controller {

    connect() {
        this.element.addEventListener('keyup', () => this.handle_keyup(this.element))
        console.log('finder conntected', this.element)
    }

    handle_keyup(elm) {
        const url = '/bank_transactions/list?query=' + encodeURI(elm.value)
        Turbo.visit(url, { frame: 'transactions-list' })
        history.pushState({}, '', url)
    }
}
import {Controller} from "@hotwired/stimulus"
import 'axios'
import axios from "axios";


export default class extends Controller {

    connect() {
        this.element.addEventListener('click', (e) => {
            const pane = e.target.closest('tr').querySelector('.transaction-info-dropdown')
            const id = pane.id.split('-')[0]
            axios.get(`/bank_transactions/info/${id}`).then(res => {
                pane.innerHTML = res.data
            })
        })
    }


}
import {Controller} from "@hotwired/stimulus"
import axios from "axios";

export default class extends Controller {

    connect() {
        this.element.addEventListener('keyup', () => {

            const customer_id = this.element.getAttribute('data-customer-id')
            const myself = this

            axios.get(`/customers/${customer_id}/properties_json`).then(function (response) {

                if (myself.element.name === 'accounting_cost_center[budget]') {
                    myself.typing_budget(response.data)
                } else if (myself.element.name === 'accounting_cost_center[budget_hours]') {
                    myself.typing_hours(response.data)
                }
            })

        })
    }

    typing_hours(resp) {
        const sug = document.getElementById('budget-suggestion')
        const sug_own = document.getElementById('hours-suggestion')
        sug.innerHTML = `${this.element.value} Stunden * Stundensatz ${resp.hourly_rate} = CHF ${(this.element.value * resp.hourly_rate).toFixed(2)}`
        sug_own.innerHTML = ''
    }

    typing_budget(resp) {
        const sug = document.getElementById('hours-suggestion')
        const sug_own = document.getElementById('budget-suggestion')
        sug.innerHTML = `CHF ${this.element.value} / Stundensatz ${resp.hourly_rate} = ${(this.element.value / resp.hourly_rate).toFixed(1)} Stunden`
        sug_own.innerHTML = ''
    }

}
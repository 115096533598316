import * as echarts from "echarts";


window.init_hours_chart = function () {


    // based on prepared DOM, initialize echarts instance
    var myChart = echarts.init(document.getElementById('hours-chart'));

    // specify chart configuration item and data
    var option = {
        title: {
            text: 'Verbrauchte Stunden'
        },
        tooltip: {
            trigger: 'axis'
        },
        xAxis: {
            type: 'category',
            data: gon.chart_labels
        },
        yAxis: {
            type: 'value'
        },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
        },
        series: [
            {
                name: 'Stunden geleistet',
                type: 'line',
                data: gon.chart_hours
            },
            {
                name: 'Stunden prognostiziert',
                type: 'line',
                data: gon.chart_hours_imp,
                lineStyle: {
                    color: '#E96F17'
                },
                itemStyle: {
                    color: '#E96F17'
                }
            }
        ]
    };

    // use configuration item and data specified to show chart
    myChart.setOption(option);
    init_progress_chart()
}


window.init_invoices_chart = function () {


    // based on prepared DOM, initialize echarts instance
    var myChart = echarts.init(document.getElementById('invoicing-chart'));

    // specify chart configuration item and data
    var option = {
        title: {
            text: 'Abrechnungsstand'
        },
        tooltip: {
            trigger: 'axis'
        },
        xAxis: {
            type: 'category',
            data: gon.chart_labels
        },
        yAxis: {
            type: 'value'
        },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
        },
        series: [
            {
                name: 'Abgerechnet',
                type: 'line',
                data: gon.chart_invoiced
            },
            {
                name: 'Prognostiziert',
                type: 'line',
                data: gon.chart_invoiced_imp,
                lineStyle: {
                    color: '#E96F17'
                },
                itemStyle: {
                    color: '#E96F17'
                }
            }
        ]
    };

    // use configuration item and data specified to show chart
    myChart.setOption(option);
    init_progress_chart()
    init_hours_chart()
}


window.init_progress_chart = function () {


    // based on prepared DOM, initialize echarts instance
    var myChart = echarts.init(document.getElementById('progress-chart'));

    // specify chart configuration item and data
    var option = {
        title: {
            text: 'Projektfortschritt'
        },
        tooltip: {
            trigger: 'axis'
        },
        xAxis: {
            type: 'category',
            data: gon.chart_labels
        },
        yAxis: {
            type: 'value'
        },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
        },
        series: [
            {
                name: 'Abrechnung',
                type: 'line',
                data: gon.chart_progress
            },
            {
                name: 'Prognostiziert',
                type: 'line',
                data: gon.chart_progress_imp,
                lineStyle: {
                    color: '#E96F17'
                },
                itemStyle: {
                    color: '#E96F17'
                }
            }
        ]
    };

    // use configuration item and data specified to show chart
    myChart.setOption(option);
}
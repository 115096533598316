import {Controller} from "@hotwired/stimulus"
import * as echarts from "echarts";

export default class extends Controller {

    connect() {
        this.element.innerHTML = 'initialized'
        const values = JSON.parse(this.element.getAttribute('data-times'))
        console.log('VALUES => ', values)
        console.log('VALUES LEGEND ', values.legend)
        console.log('VALUES SERIES ', values.series)

        const chart = echarts.init(this.element)
        const options = {
            title: {
                text: values.title
            },
            tooltip: {
                trigger: 'axis'
            },
            legend: {
                data: values.legend
            },
            xAxis: {
                type: 'category',
                boundaryGap: false,
                data: values.legend
            },
            yAxis: {
                type: 'value'
            },
            series: values.series
        }
        chart.setOption(options)
    }

}
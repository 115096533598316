import {Controller} from "@hotwired/stimulus"

export default class extends Controller {

    connect() {
        const sec = this.element.getAttribute('data-seconds') * 1000
        setTimeout(this.close, sec, this.element)
    }

    close(wrapper) {
        wrapper.querySelector('[aria-hidden]').click();
    }
}
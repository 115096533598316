
console.log('Vite ⚡️ Rails')

console.log('Visit the guide for more information: ', 'https://vite-ruby.netlify.app/guide/rails')


import "@hotwired/turbo-rails"

import 'foundation-sites'

//import '../javascript/vendor/jquery.min'
import jquery from 'jquery'; //=> ATTENTION: jQuery has to be imported after foundation. Foundation would not need this, it would be satisfied with the settings in vite.config.js
let $ = jquery;

console.log('jquery-loaded')

// cocoon

import '@nathanvda/cocoon'

import TurboPower from 'turbo_power'
TurboPower.initialize(Turbo.StreamActions)

import '@csedl/stimulus_dropdown'

// custom javascript
import '../javascript/components/accounting'
import '../javascript/components/accounting_position'
import '../javascript/components/admin_settings'
import '../javascript/components/app_and_plugin'
import '../javascript/components/checkbox_recursive'
import '../javascript/components/edit_times'
import '../javascript/components/form'
import '../javascript/components/invoicing_scripts'
import '../javascript/components/list_hovers'
import '../javascript/components/login'
import '../javascript/components/overview'
import '../javascript/components/postings'
import '../javascript/components/project_overview'
import '../javascript/components/select_times'
import '../javascript/components/session_layout'
import '../javascript/components/times_overview'
import '../javascript/components/times_table'
import '../javascript/global/close-dropdown-pane'


// stimulus

import { initStimulus } from "vite-stimulus-initializer";
const controllers = import.meta.glob('../javascript/**/*-controller.js', { eager: true })
initStimulus(controllers, 2, { debug: true, exclude: ['components', 'views'], folderSeparator: '-'  })


// initialize the page
$(document).on('turbo:render', function() {
    onPageLoad();
});
$(document).ready(function () {
    onPageLoad();
});

window.addEventListener('turbo:visit', (event) => {
    onPageLoad();
});

function onPageLoad() {
    // init foundation
    $(document).foundation();
    invoice_summarize();
    console.log('foundation ready')

}
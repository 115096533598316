import {Controller} from "@hotwired/stimulus"
import axios from "axios";

export default class extends Controller {

    connect() {
        const select = this.element.querySelector('select')
        select.onchange = () => this.change_article(this.element, select)

        const toggle_button = this.element.querySelector('.toggle-button.on')
        toggle_button.addEventListener('click', () => this.toggle_select())
        const toggle_button_off = this.element.querySelector('.toggle-button.off')
        toggle_button_off.addEventListener('click', () => this.toggle_select())
    }

    toggle_select() {
        const wrapper = this.element.getElementsByClassName('subject-wrapper')[0]
        const subj_input = wrapper.querySelector('input')
        const article_select = wrapper.querySelector('select')
        subj_input.value = ''
        article_select.value = ''

        if (wrapper.classList.contains('select-article')) {
            wrapper.classList.remove('select-article')
            article_select.setAttribute('disabled', 'disabled')
            subj_input.removeAttribute('disabled')
        } else {
            wrapper.classList.add('select-article')
            article_select.removeAttribute('disabled')
            subj_input.setAttribute('disabled', 'disabled')
        }
    }

    change_article(wrapper, input) {
        //console.log('input-value =>', input.value)

        if (input.value.split('-')[0] !== 'AccountingArticle') {
            //console.log('is not a article')
            return
        }


        const customer_id = this.element.getAttribute('data-customer-id')
        axios.get(`/customers/${customer_id}/accounting_articles/${input.value.split('-')[1]}/properties_json/`)
            .then(res => {
                const pr = wrapper.querySelector('#accounting_invoice_position_price_unit')
                const unit = wrapper.querySelector('#accounting_invoice_position_unit')
                const no = wrapper.querySelector('#accounting_invoice_position_quantity')
                const descr = wrapper.querySelector('.description')

                pr.value = res.data.price
                unit.value = res.data.unit
                no.value = res.data.quantity
                descr.value = res.data.description

                const wrapper_id = wrapper.id.split('-')[3]
                invoice_calc(wrapper_id)

            })
    }


}
import {Controller} from "@hotwired/stimulus"
import axios from "axios";

export default class extends Controller {

    connect() {
        this.element.onkeyup = () => this.change_issues()
        this.change_issues()
    }

    change_issues() {
        console.log('issues-present', ((this.element.value === '' ? 'blank' : 'present')))
        const form = this.element.closest('form')
        form.setAttribute('data-issues-present', (this.element.value === '' ? 'blank' : 'present'))
    }


}
import {Controller} from "@hotwired/stimulus"
import {clear_selected_user} from "@/javascript/views/recent-times/clear_selected_user";


export default class extends Controller {

    connect() {
        this.element.addEventListener('click', () => this.toggle_user())
    }

    toggle_user() {
        const par = this.element.closest('#users-costs')
        const id = this.element.getAttribute('data-user-id')

        if (par.getAttribute('data-selected-user') === `${id}`) {
            clear_selected_user()
        } else {
            clear_selected_user()
            this.select_user()
            this.element.classList.add('selected-user')
        }
    }

    select_user() {
        const id = this.element.getAttribute('data-user-id')
        console.log('SELECT' + id)

        // customers

        const customers = document.querySelectorAll('li.customer')
        for (const cust of customers) {
            if (cust.classList.contains(`user-${id}`)) {
                cust.classList.remove('does-not-contain-user')
            } else {
                cust.classList.add('does-not-contain-user')
            }
        }

        // cost-centers

        const cost_centers = document.querySelectorAll('li.cost-center')
        for (const cc of cost_centers) {
            let btn = cc.querySelector('.chevron-down')
            if (cc.classList.contains(`user-${id}`)) {
                cc.classList.remove('does-not-contain-user')
                let ev = new Event('expand')
                btn.dispatchEvent(ev)
            } else {
                cc.classList.add('does-not-contain-user')
                let ev = new Event('collapse')
                btn.dispatchEvent(ev)
            }
        }

        // issues

        const issues = document.querySelectorAll('li.issue')
        for (const iss of issues) {
            let btn = iss.querySelector('.chevron-down')
            if (iss.classList.contains(`user-${id}`)) {
                iss.classList.remove('does-not-contain-user')
                let ev = new Event('expand')
                btn.dispatchEvent(ev)
            } else {
                iss.classList.add('does-not-contain-user')
                let ev = new Event('collapse')
                btn.dispatchEvent(ev)
            }
        }

        // time-entries

        const time_entries = document.querySelectorAll('li.time-entries')
        for (const te of time_entries) {
            if (te.classList.contains(`user-${id}`)) {
                te.classList.remove('does-not-contain-user')
            } else {
                te.classList.add('does-not-contain-user')
            }
        }

        // set selected user

        const user_buttons = document.querySelectorAll('span.user-times-button')
        for (const ubtn of user_buttons) {
            ubtn.classList.add('does-not-contain-user')
        }
        this.element.classList.remove('does-not-contain-user')

        const par = this.element.closest('#users-costs')
        par.setAttribute('data-selected-user', id)
    }


}

window.list_item_mouseenter = function() {
    var params = event.target.getAttribute('class');
    list_item_hover('enter', params);
}
window.list_item_mouseout = function() {
    var params = event.target.getAttribute('class');
    list_item_hover('leave', params);
}

window.list_item_hover = function(mode, params) {
    $('#dev-view').html(mode);

    var cl = params.split(' ');
    var classes = sort_classes(cl);
    var me = classes.my.replace('_ME-label', '-label');
    var parents = classes.parents.replace(/\-label/g, '_ME-label');

    //$('#dev-view-2').html('classes => ' + params + ' :js => ' + JSON.stringify(classes));
    //$('#dev-view').html(':parents => ' + parents + ' :siblings => "' + classes.siblings + '"');

     if ( mode == 'enter' ) {

         $('.' + me).addClass('list-family-hover');
         $('.' + classes.my).addClass('list-family-hover-me');
         if ( classes.siblings != '') {
             $('.' + classes.siblings).addClass('list-siblings-hover');
         }


         var par = parents.split(' ');
         par.map(function (pa) {
             $('.' + pa).addClass('list-family-hover-parents');
         });


     } else {
         $('.' + me).removeClass('list-family-hover');
         $('.' + classes.my).removeClass('list-family-hover-me');
         if ( classes.siblings != '') {
             $('.' + classes.siblings).removeClass('list-siblings-hover');
         }

         var par = parents.split(' ');
         par.map(function (pa) {
             $('.' + pa).removeClass('list-family-hover-parents');
         });


     }







}

window.sort_classes = function(classes) {
    var my = '';
    var par = '';
    var type = '';
    var siblings = '';
    classes.map(function (c) {
        if (c.split('_')[1] == 'ME-label') {
            my = c;
        } else if ( c.split('_')[0] == 'timeentry-issue' ) {
            siblings = c;
        } else if (c.split('-').length == 3) {
            par = par + c + ' ';
        } else {
            type = c;
        }
    });
    return {'my': my, 'parents': par, 'type': type, 'siblings': siblings};
}